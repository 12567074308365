<script setup>

const api = useApi();

const props = defineProps({
    showLoadMore: Boolean,
    bgColor: String,
    title: {
        type: String,
        default: "Latest Articles",
    },
    showViewMore: {
        type: Boolean,
        default: true,
    },
    count: {
        type: Number,
        default: 3,
    },
});

let latestArticles = ref([]);
let articlesFiltered = ref([]);
let allArticlesShowing = ref(false);

onMounted(async () => {
    const count = props.count;

    latestArticles.value = await api.execute("api/latest-articles", "get");
    articlesFiltered.value = latestArticles.value.slice(0, count);

    if (latestArticles.value.length < count) {
        allArticlesShowing = true;
    }
});

const loadMore = () => {
    const totalCount = latestArticles.value.length;
    const currentCount = articlesFiltered.value.length;

    articlesFiltered.value = latestArticles.value.slice(0, currentCount + 3);

    if (totalCount - currentCount < 3) {
        allArticlesShowing = true;
    }
};
</script>

<template>
    <section class="container px-5 py-20 md:px-7 lg:px-14">
        <div class="flex justify-between items-center">
            <h2>
                <SplitAnimate :duration="0.4" :text="title" />
            </h2>
            <div v-if="showViewMore" class="hidden md:block">
                <Button severity="outline" border url="/blog">View More</Button>
            </div>
        </div>
        <div class="grid gap-4 grid-cols-1 mt-10 lg:grid-cols-3">
            <ArticleThumb
                v-for="(article, index) in articlesFiltered"
                :article="article"
                :bgColor="bgColor"
                :key="index"
            />
        </div>
        <div
            v-if="showLoadMore && !allArticlesShowing"
            class="flex justify-center mt-10"
        >
            <Button @click="loadMore" buttonClass="cursor-pointer" border>Load More</Button>
        </div>
        <div v-else class="block text-center mt-10 md:hidden">
            <Button url="/blog" border>View More</Button>
        </div>
    </section>
</template>